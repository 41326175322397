.nft-token.embed {
  width: 100%;
  padding-top: 135%;
  position: relative;
  perspective: 35cm;
  font-size: 1rem;
}

.flipper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  perspective-origin: center;
  transition: all 0.7s ease-in-out;
}

.flipper.flipped {
  transform: rotateY(180deg);
}

.flipper .face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 1rem;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.flipper .face.front {
  -ms-transform: translateZ(1px);
    -webkit-transform: translateZ(1px);
    -moz-transform: translateZ(1px);
    -o-transform: translateZ(1px);
    transform: translateZ(0px);
}

.flipper .face.back {
  transform: rotateY(180deg) translateZ(1px);
  pointer-events: none;
}

.flipper.flipped .face.back {
  pointer-events: all;
}

.token-image img,
.token-image video {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
}

.token-image .fill video {
  object-fit: fill;
}

.fullscreen-button {
  position: absolute;
  z-index: 10;
  top: 45%;
  width: 100%;
  opacity: 0;
  text-align: center;
  transition: opacity 0.4s ease-in-out;
  pointer-events: none;
}

.fullscreen-button .fs {
  pointer-events: visiblePainted;
  display: inline-block;
  width: 2.4em;
  height: 2.4em;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border-radius: 0.35rem;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 1.2em;
  border-radius: 0.35rem;
  padding: 0.6em 0.8em 0.5em 0.8em;
  transition: opacity 0.4s ease-in-out, background-color 0.4s ease-in-out;
  background-position: center center;
  background-size: 50%;
  background-repeat: no-repeat;
}

.fullscreen-button .fs:hover {
  background-color: #be1fda;
}

.front-face:hover .fullscreen-button {
  opacity: 0;
}

.fullscreen-button .fs.sw {
  background-size: 65%;
}

.card-icon {
  position: absolute;
  top: 1em;
  background: red;
  padding: 0.25em;
  border-radius: 0.25em;
  cursor: pointer;
  z-index: 1000;
}

.card-icon img {
  max-width: 32px;
}

.card-icon.left {
  left: 1em;
}

.card-icon.right {
  right: 1em;
}

.card-descr h5 {
  color: #5b5b5b;
  font-size: 1.1em;
}
/* 
.embed .trait{
    max-width: 96px;
    max-height: 86px;
} */

.trait-container img {
  /*     max-width: 64px;
    margin-right:1em;
    margin-bottom: 1em;
    cursor: pointer; */
}

.trait-container img.disabled {
  opacity: 0.5;
}

.embed .trait-details h5 {
  font-size: 1.6em;
  line-height: 1.2em;
}

.embed .trait-details p {
  font-size: 0.75em;
}

.embed .trait-details .trait {
  max-width: 140px;
  max-height: 120px;
}

.embed .details-content {
  font-size: 0.7em;
}

.embed .details-content h3 {
  font-size: 2.1em;
}

.f-link {
  color: red;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.f-link:hover {
  color: darkred;
}

.embed .fullscreen-button .fs {
  opacity: 0.1;
}
.embed .fullscreen-button:hover .fs {
  opacity: 1;
}

.face:hover .fullscreen-button {
  opacity: 1;
}

.face.front::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 100%;
  display: block;
  background: linear-gradient(
    112deg,
    rgba(255, 255, 255, 0) 35%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 65%
  );
  background-position-x: 0;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.35s;
  pointer-events: none;
}

.face.back::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 100%;
  display: block;
  background: linear-gradient(
    112deg,
    rgba(255, 255, 255, 0) 35%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 65%
  );
  background-position-x: 0;
  background-repeat: no-repeat;
  transition: all ease-in-out 0.35s;
  transition-delay: 0.35s;
  pointer-events: none;
}

.flipped .face.front::after {
  left: -50%;
}

.flipped .face.back::after {
  left: -90%;
}

.embed.c-xlarge {
  font-size: 2rem;
}

.embed.c-large {
  font-size: 1.5rem;
}

.card-buttons{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.card-buttons-2{
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
}

.card-type {
  position: absolute;
  z-index: 10;
  top: 3%;
  left: 3%;
  background-color: #000;
  color: #fff;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1em;
  /* line-height: 1em; */
  border-radius: 0.6em;
  padding: 0.4em 0.3em 0.4em 0.3em;
  opacity: 1;
  transition: opacity 0.4s ease-in-out, background-color 0.4s ease-in-out;
  cursor: pointer;
}

.card-traits {
  position: absolute;
  z-index: 10;
  top: 3%;
  right: 3%;
  background-color: #000;
  color: #fff;
  font-family: Poppins;
  font-weight: 600;
  font-size: 1em;
  /* line-height: 1em; */
  border-radius: 0.6em;
  padding: 0.4em 0.3em 0.4em 0.3em;
  cursor: pointer;
  opacity: 1;
  text-align: center;
  min-width: 2.4em;
  transition: opacity 0.4s ease-in-out, background-color 0.4s ease-in-out;
}
.card-traits:hover,
.card-type:hover {
  background-color: #F51CDD;
}

.dust-on .card-type,
.dust-on .card-traits,
.dust-on .fullscreen-button,
.traits-on .card-type,
.traits-on .card-traits,
.traits-on .fullscreen-button,
.details-on .card-type,
.details-on .card-traits,
.details-on .fullscreen-button,
.gift-on .card-type,
.gift-on .card-traits,
.gift-on .fullscreen-button,
.phoenix-on .card-type,
.phoenix-on .card-traits,
.phoenix-on .fullscreen-button  {
  opacity: 0 !important;
  pointer-events: none;
}

.ec-logo {
  position: absolute;
  width: 100%;
  height: 9%;
  z-index: 9;
  bottom: 4%;
  left: 0;
  text-align: right;
  text-align: center;
  pointer-events: none;
}
.ec-logo img {
  height: 100%;
  filter: drop-shadow(0 1px 2px #000);
}

.unresolved .ec-logo {
  display: none;
}

.trait-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: perspective(35cm) rotateY(90deg);
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  transform-origin: top right;
  z-index: 11;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 2px #fff;
}

.trait-slide .close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.close-btn img {
  width: 16px;
  height: 16px;
}

.close-btn:hover {
  filter: brightness(0) saturate(100) invert(23%) sepia(93%) saturate(5853%)
    hue-rotate(286deg) brightness(98%) contrast(93%);
}

.traits-on .trait-slide {
  transform: scale(1);
  opacity: 1;
}

.trait-container {
  position: absolute;
  width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  top: 32px;
  left: 5%;
  bottom: 1.25rem;
  transition: all 0.7s ease-in-out;
}
.trait-container::-webkit-scrollbar,
.trait-details::-webkit-scrollbar,
.details-content::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background: none;
}

.trait-container::-webkit-scrollbar-thumb,
.trait-details::-webkit-scrollbar-thumb,
.details-content::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: rgba(230, 3, 250, 0.474) !important;
}

.trait-container.trait-selected {
  left: -100%;
}

.trait {
  margin: 0 0 1rem 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  height: 15%;
  max-height: 64px;
}
.trait img {
  width: 100%;
  /*  height: 100%;
    max-height: 64px; */
  margin-right: 1rem;
}

.trait:hover {
  filter: brightness(0) saturate(100) invert(23%) sepia(93%) saturate(5853%)
    hue-rotate(286deg) brightness(98%) contrast(93%);
}

.trait-details {
  position: absolute;
  width: 90%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
  top: 2rem;
  left: 100%;
  bottom: 1.25rem;
  transition: left 0.7s ease-in-out;
}

.trait-details.trait-selected {
  left: 5%;
}
.trait-details .trait {
  display: inline-block;
  float: none;
}
.trait-details .trait:hover {
  filter: none;
  cursor: default;
}
.trait-name {
  font-family: teko;
  font-size: 1.8rem;
  line-height: 1.6rem;
  text-transform: uppercase;
}

.details-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  transform-origin: top left;
  z-index: 11;
  overflow: hidden;
  transform: scale(0);
  transition: all 0.4s ease-in-out;
  opacity: 0;
}

.details-slide .close-btn {
  position: fixed;
  top: 15px;
  right: 15px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

.details-on .details-slide {
  transform: scale(1);
  opacity: 1;
}

.details-content {
  position: absolute;
  width: 92%;
  left: 4%;
  right: 4%;
  top: 10%;
  bottom: 4%;
  font-size: 1rem;
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.details-content h3 {
  font-family: teko;
  color: #fff;
  font-size: 1.8em;
}
.details-content h5 {
  font-family: teko;
  color: #ccc;
  font-size: 1.2em;
}

.details-content p {
  color: #ccc;
  font-size: 1em;
}

.details-content p strong {
  color: #fff;
  font-weight: 600;
}

.c-small .details-content {
  font-size: 0.9rem;
  overflow-y: auto;
}

.c-xsmall .front-face {
  font-size: 0.7rem;
}

.c-xsmall .details-content {
  font-size: 0.8rem;
  overflow-y: auto;
}

.c-xsmall .token-image.sensitive .overlay {
  font-size: 0.8rem;
}

.c-xsmall .card-buttons {
  font-size:0.5rem
}

.c-small .card-buttons {
  font-size:0.7rem
}