@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

@font-face {
  font-family: "subspace";
  src: local("subspace"), url(./Subspace.otf) format("opentype");
}
@font-face {
  font-family: "teko";
  src: local("teko"), url(./assets/fonts/Teko-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Believer";
  src: url(./assets/fonts/Believer.ttf) format("woff");
}

:root {
  --primary-color: #ad33a1;
  --black: #000;
  --white: #fff;
  --background-color: #1c1e24;
}

.btn-purple {
  border: solid 2px #be1fda;
  border-radius: 8px;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 0.8rem;
  background-color: #1c1e24;
  white-space: pre-wrap;
}
.btn-purple:hover,
.btn-purple:active {
  cursor: pointer;
  background: #ad33a1;
}

.card-title {
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
  color: white;
}

.card-no {
  font-size: 1.8em;
  font-weight: 500;
  text-align: center;
  color: #ad33a1;
}

.submit-bg {
  background-color: #111318;
  border: radius 25px;
  padding: 40px;
  border-radius: 25px;
}
.submit-title {
  color: white;
  text-align: center;
}

.center-panel {
  margin-left: auto;
  margin-right: auto;
}

.thumbnail {
  position: absolute;
  top: 0;
  z-index: -5000;
  width: 0px;
  height: 0px;
}
.thumbnail img {
  object-fit: cover;
  width: 0px;
  height: 0px;
}

.bn-onboard-custom {
  z-index: 2323;
}

.clickable {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clickable:hover {
  color: #be1fda;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  background: #1b1e25;
  color: #969da3;
  height: 100%;
}
footer {
  display: flex;
  flex-direction: column;
  background: #111317;
  margin-top: auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  color: #ffffff;
}
h4,
h5 {
  font-weight: 500;
  color: #ffffff;
}
#root {
  height: 100%;
}
#application {
  padding-top: 0;
  background: none;
  position: relative;
  min-height: 100%;
  /* padding-bottom: 100px; */
}
.btn.round {
  border-radius: 0.6rem;
}
.btn-green {
  background-color: #94e316;
  color: black;
}

.btn-peach {
  border-color: #F51CDD;
  border: solid 3px #F51CDD;
  background-color: #F51CDD;
  color: black;
  white-space: pre-wrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-green.focus,
.btn-green:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(148, 227, 22, 0.25);
}

.btn-peach.focus,
.btn-peach:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(189, 92, 153, 0.25);
}

.btn-outline {
  background: none;

  border: solid 3px;
}

.btn-outline.btn-peach {
  border-color: #F51CDD;
  color: white;
}

.btn-outline.btn-peach:hover {
  background-color: #F51CDD;
  color: #fff;
}

.btn-peach:hover {
  background-color: #1b1e25;
  color: #F51CDD;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #3f3d3a;
}

.modal-header {
  border-bottom: 1px solid #949393;
}
.modal-footer {
  border-top: 1px solid #949393;
}

.close {
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: 0.5;
}

.toastContainer {
  z-index: 2000;
}

.text-peach {
  color: #be1fda;
}

.text-lilac {
  color: #771197;
}

.bold-text {
  font-weight: 400;
}

/* modal overrides... */
.web3modal-provider-container {
  padding: 4px !important;
}
.web3modal-provider-icon {
  width: 32px !important;
  height: 32px !important;
}
.web3modal-provider-name {
  font-size: 1.1em !important;
  margin-top: 0.3em !important;
}
.web3modal-provider-description {
  font-size: 0.9em !important;
  margin: 0.2em 0px !important;
}
.web3modal-modal-card {
  max-width: 710px !important;
}

.loader {
  width: 30px;
  height: 30px;
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;
}
.loader .internal-circle,
.loader .external-circle {
  stroke: #fff;
  stroke-dashoffset: 0;
  transform-origin: center;
}
.loader .internal-circle {
  stroke-dasharray: 187;
  animation: internal 1s ease-in-out infinite;
  opacity: 0.4;
}
.loader .external-circle {
  stroke-dasharray: 312;
  animation: external 1s linear infinite;
  opacity: 0.9;
}
@keyframes internal {
  0% {
    stroke-dashoffset: 187;
  }
  25% {
    stroke-dashoffset: 80;
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(360deg);
  }
}
@keyframes external {
  0% {
    stroke-dashoffset: 312;
    transform: rotate(70deg);
  }
  60% {
    stroke-dashoffset: -312;
  }
  100% {
    stroke-dashoffset: -312;
    transform: rotate(450deg);
  }
}

.hidden {
  display: none;
}
