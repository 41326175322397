#pre-footer {
  background: #111317;
  padding: 50px 0;
  text-align: left;
}
@media (max-width: 767px) {
  #pre-footer .col,
  #pre-footer .newsletter-title .h4,
  #pre-footer .newsletter-title h4 {
    text-align: center;
  }
  #pre-footer .col {
    flex: none;
    width: 100%;
  }
  #pre-footer h4 {
    font-size: 16px;
  }
  #pre-footer li a {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  #pre-footer .mb-3 {
    padding-right: 40px;
  }
}
#pre-footer p {
  color: rgb(154, 152, 154);
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 1rem;
  margin-top: 0px;
}
#pre-footer .h4,
#pre-footer h4 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 600 !important;
}
#pre-footer .h5,
#pre-footer h5 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 600 !important;
}
#pre-footer input {
  color: #fff;
  background: #000;
  border: none;
  padding: 0 20px;
}
#pre-footer .btn-purple-fill {
  background: #F51CDD;
  border-radius: 0 10px 10px 0;
  color: #fff;
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
}
#pre-footer .btn-purple-fill:active,
#pre-footer .btn-purple-fill:hover {
  background: #f51cdcd3;
  color: #fff;
}
#pre-footer ul {
  list-style: none;
  padding: 0;
}
#pre-footer ul li {
  color: #fff;
  padding-bottom: 10px;
}
#pre-footer li a {
  color: rgb(154, 152, 154);
  font-size: 14px;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  transition: opacity 0.15s ease-in-out 0s, color 0.15s ease 0s,
    background-color 0.15s ease-in-out 0s;
}

#pre-footer li a:hover {
  opacity: 1;
  font-weight: 400;
  color: var(--primary-color);
}
#pre-footer .social-icons img {
  height: 22px;
  margin-right: 10px;
}
#pre-footer .social-icons img:active,
#pre-footer .social-icons img:hover {
  filter: sepia(50%) saturate(2000%) brightness(70%) hue-rotate(214deg);
}

#pre-footer .form-control {
  height: unset;
  padding: 0px 20px !important;
}

/* EMAIL SUBSCRIPTION MODAL */

.spinner-border {
  display: none !important;
  height: 1rem !important;
  width: 1rem !important;
  margin-bottom: 3px;
  vertical-align: middle;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border.loading {
  display: inline-block !important;
}

#thankYouPopup {
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.6);
}

#thankYouPopup.modal.show {
  display: flex;
  flex-direction: column;
}

#thankYouPopup .modal-header {
  border: 0px;
  background-color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#thankYouPopup .modal-header button:focus {
  outline: none;
}
#thankYouPopup .modal-header button:hover {
  filter: brightness(0.9);
}

#thankYouPopup .modal-header button.close {
  position: absolute;
  z-index: 1;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  right: 10%;
  top: 10%;
  color: white;
  background-color: transparent;
  border: 0;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
#thankYouPopup .modal-dialog {
  margin: auto;
}
#thankYouPopup .modal-content {
  background-color: transparent;
}
#thankYouPopup .modal-body {
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
#thankYouPopup .modal-backdrop.show {
  opacity: 0;
}
.thank-you-popup {
  width: 100%;
  padding: 20px;
  text-align: center;
}
.thank-you-popup img {
  display: block;
  width: 80px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 25px;
}
.thank-you-popup p {
  font-size: 18px;
  margin-bottom: 27px;
  color: white;
}
.thank-you-popup h2 {
  color: var(--primary-color);
}

.thank-you-popup a {
  color: white !important;
}
.thank-you-popup a.btn:hover {
  opacity: 1;
}
