.fs-layer-container{
    position: relative;
    height: 100%;
    width: 100%;
}

.fs-layer-container img{
    display: block;
    position: absolute;
    top: 0;
    right: auto;
    height: 100%;
    transition: all 0.1s ease-out;
    pointer-events: none;
}